<template>
    <div
      class="titles"
      :class="allType == 2 ? 'all_title_box01' : 'all_title_box02'"
      @mouseenter="mouseOn()"
      @mouseleave="mouseOff()"
    >
      <div class="all_title">
        <div class="all_box">
          <div class="title_box">
            <div class="title_img">
              <img src="@/assets/logo.png" v-show="allType == 2" />
              <img src="@/assets/logo_on.png" v-show="allType == 3" />
            </div>
            <div class="nav_phone">
              <div class="title_nav_list">
                <div
                  class="title_nav_item"
                  @click="tabClick('Home_en')"
                  :class="$route.name == 'Home' ? 'gl_on' : 'gl_off'"
                >
                home
                </div>
                <div
                  class="title_nav_item"
                  @click="tabClick('Home_en')"
                  :class="$route.name == 'About' ? 'gl_on' : 'gl_off'"
                >
                about BangMang
                </div>
                <div
                  class="title_nav_item"
                  @click="sonNavType = !sonNavType"
                  :class="
                    $route.name == 'Social' ||
                    $route.name == 'Labour' ||
                    $route.name == 'Human' ||
                    $route.name == 'Job' ||
                    $route.name == 'Userout' ||
                    $route.name == 'Pay'
                      ? 'gl_on'
                      : 'gl_off'
                  "
                >
                Business Scope
                </div>
                <div
                  class="title_nav_item"
                  @click="tabClick('Home_en')"
                  :class="$route.name == 'Scheme' ? 'gl_on' : 'gl_off'"
                >
                Industry Solution
                </div>
                <div
                  class="title_nav_item"
                  @click="tabClick('Home_en')"
                  :class="$route.name == 'Duty' ? 'gl_on' : 'gl_off'"
                >
                Social Responsibility
                </div>
                <div
                  class="title_nav_item"
                  @click="tabClick('Home_en')"
                  :class="$route.name == 'Contact' ? 'gl_on' : 'gl_off'"
                >
                Contact us  
                </div>

                <div
                  class="title_nav_item"
                  @click="tabClick('Home')"
                >
                中文
                </div>

              </div>
              <div class="title_nav_phone">
                <img src="@/assets/phone_on.png" v-show="allType == 3" />
                <img src="@/assets/phone.png" v-show="allType == 2" />400-891-5050
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ywfw_box" v-if="sonNavType">
        <div class="ywfw_list">
          <div class="ywfw_item" @click="tabClick('Userout')">人事外包</div>
          <div class="ywfw_item" @click="tabClick('Social')">企业社保服务</div>
          <div class="ywfw_item" @click="tabClick('Labour')">劳务派遣</div>
          <div class="ywfw_item" @click="tabClick('Human')">劳务外包</div>
          <div class="ywfw_item" @click="tabClick('Pay')">薪酬服务</div>
          <div class="ywfw_item" @click="tabClick('Job')">招聘外包</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        //2:置顶 3:下拉
        allType: 2,
        sonNavType: false,
      };
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
      //鼠标移入
      mouseOn() {
        this.allType = 3;
      },
      //鼠标移出
      mouseOff(e) {
        console.log(".33");
        const winHeight = document.documentElement.scrollTop;
        if (winHeight > 0) {
          this.allType = 3;
        } else {
          this.allType = 2;
        }
      },
      handleScroll(e) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
  
        if (winHeight > 0) {
          this.allType = 3;
        } else {
          this.allType = 2;
        }
      },
      tabClick(item) {
        this.$router.push({
          name: item,
        });
      },
    },
  };
  </script>
  
  
  <style lang="scss" scoped>
  .all_title_box01 {
    .all_title {
      background: none;
    }
    .title_nav_item {
      color: #fff !important;
    }
    .title_nav_phone {
      color: #fff !important;
    }
  }
  .all_title_box02 {
    .all_title {
      background: #fff;
    }
    .title_nav_item {
      color: #696969 !important;
    }
    .title_nav_phone {
      color: #d70035 !important;
    }
  }
  
  .titles {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50;
  }
  .ywfw_box {
    background: #242424;
    position: absolute;
    left: 690px;
    top: 88px;
    width: 700px;
    height: 60px;
    line-height: 60px;
    padding: 0 43px;
    .ywfw_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .ywfw_item {
        color: #eae9e9;
        cursor: pointer;
      }
    }
  }
  .gl_on::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #d70035;
    position: absolute;
    left: 0;
    bottom: -16px;
  }
  .title_img {
    img {
      width: 100%;
    }
  }
  
  .title_nav_phone {
    line-height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    img {
      margin-right: 10px;
    }
  }
  .title_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .nav_phone {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .title_nav_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .title_nav_item {
      line-height: 50px;
      margin: 0 20px;
      color: #fff;
      font-size: 16px;
      position: relative;
      cursor: pointer;
    }
  }
  .all_title {
    position: absolute;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  </style>