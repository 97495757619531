<!-- 通用about -->
<template>
  <div class="aboutText">
    <div class="about_text_box">
      <p>
        BANMANG SERVICES Outsourcing Co., Ltd. was founded in 2007. The business covers service outsourcing, personnel agency, human resources SAAS services, enterprise welfare platform, industrial park operation, investment in investment, tax agency, industrial investment and other multi-channel business, and actively explore IT R&D, Internet direct hiring platform and other innovation fields, to create a one-stop whole industrial chain group enterprise with the synergies model of business collaboration and resource interaction.
      </p>
      <p>
        With {{ $config.bmYears }} cultivation, Bangmang has realized the layout of the core market in China, and at the same time established companies in Hong Kong and the United States, China, there are 228 direct branches in the world, and the service employees more than 800,000.
      </p>
      <p>
        In the future, BangMang will continue to adhere to technology-driven, customer-centric, relying on the unique multi-industry cluster service platform and service the global backbone network and the global talent database, providing full-format, full-cycle, digital “one-stop solution”. "Great wisdom will prosper the nation, and great products will spread ", the BangMang divisions efficiently and synergies, gathered together and win-win, to promote stable employment and protect people's livelihood continuously efforts!
      </p>
    </div>
    <div class="about_number_list">
      <div class="about_number_item">
        <h4>228</h4>
        <p>More than 238 service direct Companies in the world </p>
      </div>
      <div class="about_number_item">
        <h4>400</h4>
        <p>Business radiates more than 400 cities.</p>
      </div>
      <div class="about_number_item">
        <h4>1000000+</h4>
        <p>Provide quality service to more than 800,000 employees</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>

.about_text_box {
  font-size: 16px;
  color: #696969;
  line-height: 24px;
  text-align: left;
  text-indent: 2em;
  p {
    margin: 25px 0;
  }
}
.about_number_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .about_number_item:first-child {
    margin-left: 0;
  }
  .about_number_item {
    text-align: left;
    width: 25%;
    h4 {
      font-size: 24px;
      margin: 0;
      color: #b89369;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      margin: 0;
      color: #3f3a39;
    }
  }
}

</style>