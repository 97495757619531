<template>
  <div class="foots">
    <div class="all_foot" :class="type == 3 ? 'noPadding' : ''">
      <div class="all_box">
        <div class="foot_box">
          <div class="foot_left">
            <div class="foot_left_item">
              <div class="foot_item_item" @click="clickNav('/about', 1)">About BangMang</div>
              <div class="foot_item_item" @click="clickNav('/about', 1)">邦芒简介</div>
              <div class="foot_item_item" @click="clickNav('/about', 2)">企业文化</div>
              <div class="foot_item_item" @click="clickNav('/about', 3)">发展历程</div>
              <div class="foot_item_item" @click="clickNav('/about', 4)">公司荣誉</div>
              <div class="foot_item_item" @click="clickNav('/about', 5)">业务架构</div>
            </div>
            <div class="foot_left_item">
              <div class="foot_item_item" @click="clickNav('/userout')">Business Scope</div>
              <div class="foot_item_item" @click="clickNav('/userout')">人事外包</div>
              <div class="foot_item_item" @click="clickNav('/social')">社保服务</div>
              <div class="foot_item_item" @click="clickNav('/labour')">劳务派遣</div>
              <div class="foot_item_item" @click="clickNav('/human')">劳务外包</div>
              <div class="foot_item_item" @click="clickNav('/pay')">薪酬服务</div>
              <div class="foot_item_item" @click="clickNav('/job')">招聘外包</div>
            </div>
            <div class="foot_left_item">
              <div class="foot_item_item" @click="clickNav('/scheme', 0)">Industry Solution</div>
              <div class="foot_item_item" @click="clickNav('/scheme', 0)">物流运输</div>
              <div class="foot_item_item" @click="clickNav('/scheme', 1)">生活服务</div>
              <div class="foot_item_item" @click="clickNav('/scheme', 2)">工业制造</div>
              <div class="foot_item_item" @click="clickNav('/scheme', 3)">酒店餐饮</div>
            </div>
            <div class="foot_left_item">
              <div class="foot_item_item" @click="clickNav('/duty')">Social Responsibility</div>
            </div>
            <div class="foot_left_item">
              <div class="foot_item_item" @click="clickNav('/contact')">Contact us</div>
            </div>
          </div>
          <div class="foot_right_box">
            <div class="foot_text">
              <div class="foot_right_phone">
                <div class="foot_phone_text">
                  <h4>全国服务热线</h4>
                  <p>400-891-5050</p>
                </div>
                <div class="foot_phone_btn"><a
                    href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db"
                    target="_blank">Online consultation</a></div>
                <div class="foot_complaint">
                  <h3>Complaint hotline:</h3>
                  <p>0573-82722090</p>
                </div>
              </div>
            </div>
            <!-- <div class="erweima">
              <img src="@/assets/erweima.jpg" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="beian">版权所有：邦芒服务外包有限公司 浙ICP备19006763号-3</div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clickNav(path, type) {
      if (this.$route.path == path) {
        this.$emit('navChange', type)
      } else {
        this.$router.push({
          path: path,
          query: {
            type: type
          }
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.beian {
  background: #2b3950;
  text-align: center;
  padding: 37px 0;
  color: #e4e5e5;
  font-size: 14px;
}

.noPadding {
  padding: 70px 0 55px !important;
}

.all_foot {
  padding: 210px 0 55px;
  background: #f2f5fa;
}

.foot_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .foot_left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 40%;

    .foot_left_item {
      .foot_item_item:first-child {
        font-size: 14px;
        color: #3b3b3b;
      }

      .foot_item_item {
        font-size: 14px;
        color: #787878;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  .erweima {
    text-align: left;
  }

  .foot_right_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .foot_right_phone {
      .foot_complaint {
        text-align: left;

        h3 {
          font-size: 14px;
          color: #3b3b3b;
          margin: 0;
          font-size: 14px;
          font-weight: inherit;
        }

        p {
          font-size: 20px;
          color: #d70035;
          margin: 0;
          margin-top: 14px;
          font-weight: bold;
        }
      }

      .foot_phone_text {
        text-align: left;
        margin-right: 38px;

        h4 {
          font-size: 14px;
          color: #3b3b3b;
          margin: 0;
          margin-bottom: 5px;
        }

        p {
          font-size: 30px;
          color: #d70035;
          font-weight: bold;
          margin: 0;
        }
      }

      .foot_phone_btn {
        a {
          color: #fff;
          display: block;
          text-decoration: none;
        }

        width: 170px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        background: #d70035;
        margin: 25px 0 45px;
      }
    }
  }
}</style>