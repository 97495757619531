<template>
  <div class="message">
    <el-form :model="messageData" :rules="messageDataRules" ref="messageData">
      <el-form-item class="message_item" prop="contacts">
        <div class="bitian">
          *
        </div>
        <img src="@/assets/message_name.png" class="message_img" />
        <el-input v-model="messageData.contacts" placeholder="your name"></el-input>
      </el-form-item>
      <el-form-item class="message_item" prop="phone">
        <div class="bitian">
          *
        </div>
        <img src="@/assets/message_tel.png" class="message_img" />
        <el-input v-model="messageData.phone" placeholder="your phone number"></el-input>
      </el-form-item>
      <el-form-item class="message_item" prop="cityName">
        <div class="bitian">
          *
        </div>
        <img src="@/assets/message_add.png" class="message_img" />
        <el-input v-model="messageData.cityName" placeholder="your city "></el-input>
      </el-form-item>
      <el-form-item class="message_item">
        <img src="@/assets/message_qy.png" class="message_img" />
        <el-input v-model="messageData.companyName" placeholder="your company"></el-input>
      </el-form-item>
      <el-form-item class="message_item"  prop="msg">
        <div class="bitian">
          *
        </div>
        <img src="@/assets/message_yw.png" class="message_img" />
        <el-select v-model="messageData.msg" placeholder="select" style="width:100%">
          <el-option v-for="item in options.ywList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="message_submit" @click="messageSubmit">submit</div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import _service from "@/service";
export default {
  data() {
    return {
      messageData: {
        contacts: "",
        phone: "",
        email: "",
        msg: "",
        cityName:"",
      },
      options:{
        ywList:[
          {
            value: '人事外包',
            label: '人事外包'
          },{
            value: '企业社保服务',
            label: '企业社保服务'
          },{
            value: '劳务派遣',
            label: '劳务派遣'
          },{
            value: '劳务外包',
            label: '劳务外包'
          },{
            value: '薪酬服务',
            label: '薪酬服务'
          },{
            value: '招聘外包',
            label: '招聘外包'
          },
        ]
      }
    };
  },
  computed: {
    messageDataRules() {

      var phoneJy = (rule,value,callback) =>{
        var myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        debugger
					if (!value) {
						return callback(new Error('请输入必填项'))
					}
					if (!myreg.test(value)) {
						return callback(new Error('格式不正确！'))
					}
					callback()
      }


      return {
        contacts: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur"
          },
        ],
        phone: [
          {
            required: true,
						validator: phoneJy,
						trigger: 'blur'
          },
        ],
        cityName: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur"
          }
        ],
        msg: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur"
          },
        ],

      }
    }
  },
  methods: {
    //表单提交

    messageSubmit: debounce(function () {
      this.$refs['messageData'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.messageData.columnValue = 0
        this.messageData.source = "www.50bm.com.cn";
        _service.messageSubmit({ ...this.messageData }).then((res) => {
          if (!res) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.messageData = {}
            return;
          }
          this.$message.error(`提交失败`);
        });
      })
    })


  },
};
</script>
<style lang="scss">
.message {
  img {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
  }

  .message_item {
    position: relative;
  }

  .el-input__wrapper {
    border-radius: 5px;
    padding: 10px 50px 10px 50px;
  }

  .bitian {
    position: absolute;
    z-index: 10;
    right: 20px;
    font-size: 20px;
    top: 13px;
    color: #d70035;
  }
}
</style>
<style lang="scss" scoped>
.message_submit {
  width: 100%;
  text-align: center;
  height: 55px;
  line-height: 55px;
  border-radius: 10px;
  background: #d70035;
  color: #fff;
}
</style>